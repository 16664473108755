// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-array-mdx": () => import("../../src/pages/array.mdx" /* webpackChunkName: "component---src-pages-array-mdx" */),
  "component---src-pages-call-mdx": () => import("../../src/pages/call.mdx" /* webpackChunkName: "component---src-pages-call-mdx" */),
  "component---src-pages-datetime-mdx": () => import("../../src/pages/datetime.mdx" /* webpackChunkName: "component---src-pages-datetime-mdx" */),
  "component---src-pages-debounce-mdx": () => import("../../src/pages/debounce.mdx" /* webpackChunkName: "component---src-pages-debounce-mdx" */),
  "component---src-pages-event-emitter-mdx": () => import("../../src/pages/event-emitter.mdx" /* webpackChunkName: "component---src-pages-event-emitter-mdx" */),
  "component---src-pages-filter-mdx": () => import("../../src/pages/filter.mdx" /* webpackChunkName: "component---src-pages-filter-mdx" */),
  "component---src-pages-generation-mdx": () => import("../../src/pages/generation.mdx" /* webpackChunkName: "component---src-pages-generation-mdx" */),
  "component---src-pages-getting-started-mdx": () => import("../../src/pages/getting-started.mdx" /* webpackChunkName: "component---src-pages-getting-started-mdx" */),
  "component---src-pages-number-mdx": () => import("../../src/pages/number.mdx" /* webpackChunkName: "component---src-pages-number-mdx" */),
  "component---src-pages-money-mdx": () => import("../../src/pages/money.mdx" /* webpackChunkName: "component---src-pages-money-mdx" */),
  "component---src-pages-other-mdx": () => import("../../src/pages/other.mdx" /* webpackChunkName: "component---src-pages-other-mdx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

